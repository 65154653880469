<template>
  <v-layout class="fill-height overflow-x-hidden overflow-y-auto" column>
    <div class="pa-2" style="height: fit-content; width: 100%; flex: none">
      <v-row style="height: fit-content; overflow-y: hidden">
        <v-col class="pr-5" cols="12" md="4" style="height: fit-content; text-align: center">
          <v-btn-toggle v-model="menu" color="deep-purple accent-3" group mandatory style="width: 100%" tile>
            <v-btn min-width="0" style="font-size: 0.65vw" value="overview" width="33%">
              Overview
            </v-btn>

            <v-btn min-width="0" style="font-size: 0.65vw" value="results" width="33%">
              Top 10 Results
            </v-btn>

            <v-btn min-width="0" style="font-size: 0.65vw" value="time" width="33%">
              Time serial
            </v-btn>
            <!--            <v-btn value="compare" style="font-size: 0.65vw" width="33%" min-width="0">-->
            <!--              Compare Changes-->
            <!--            </v-btn>-->
          </v-btn-toggle>
        </v-col>
        <v-col class="pl-2" cols="12" md="8" style="height: fit-content;">
          <v-row>
            <v-col ref="AOI" cols="12" md="3" sm="12">
              <select2
                v-model="currentAOI"
                :disabled="editing"
                :items="listAOI"
                :loading="loading"
                clearable
                dense
                hide-details
                item-text="name"
                item-value="uuid"
                label="Select AOI"
                outlined
                placeholder="AOI"
                return-object
                @change="getService"
              />
            </v-col>
            <v-col ref="service" cols="12" md="3" sm="12">
              <v-select
                v-model="service"
                :disabled="!currentAOI || editing"
                :items="services"
                :loading="loading"
                clearable
                dense
                hide-details
                item-text="service_name"
                label="Select service"
                outlined
                placeholder="Service"
                return-object
              />
            </v-col>
            <v-col ref="source" cols="12" md="3" sm="12">
              <v-select
                v-model="source"
                :disabled="!service || editing"
                :items="sources"
                :loading="loading"
                clearable
                dense
                hide-details
                item-text="name"
                label="Select source"
                outlined
                placeholder="Source"
                return-object
                @change="getListResult"
              />
            </v-col>
            <v-col ref="result" v-if="menu !== 'time'" cols="12" md="3" sm="12">
              <v-select
                v-model="result"
                :disabled="!source || editing"
                :items="data"
                :loading="loading"
                clearable
                dense
                hide-details
                item-text="date"
                label="Date"
                outlined
                placeholder="Date"
                return-object
                @change="filterData"
              >
              </v-select>
            </v-col>
            <v-col v-else cols="12" md="3" sm="12">
              <v-select
                v-model="frequency"
                :disabled="!source || editing"
                :items="frequencies"
                :loading="loading"
                clearable
                dense
                hide-details
                item-text="frequency"
                label="Frequency"
                outlined
                placeholder="Frequency"
                return-object
                @change="filterData"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <Overview
      v-if="menu === 'overview'"
      ref="overview"
      :aoi.sync="currentAOI"
      :selected-result.sync="result"
      :selected-service.sync="service"
      :selected-source.sync="source"
    />
    <TopResult
      v-if="menu === 'results'"
      ref="results"
      :aoi.sync="currentAOI"
      :selected-result.sync="result"
      :selected-service.sync="service"
      :selected-source.sync="source"
    />
    <TimeSerial
      v-if="menu === 'time'"
      ref="time"
      :aoi.sync="currentAOI"
      :frequency.sync="frequency"
      :selected-result.sync="result"
      :selected-service.sync="service"
      :selected-source.sync="source"
    />
    <MakeOrder ref="makeOrder" isOnScreenDialog />
    <TourGuide
      style="position: absolute; bottom: 408px; right: 125px; z-index: 2"
      ref="tourGuide"
      :color="'#e3e3e3'"
      :iconColor="'#66808d'"
      v-if="elements"
      :elements="elements"
    />
  </v-layout>
</template>
<script>
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import { getOrder } from '@/api/order'
import { getChangeDetectionResult } from '@/api/change-detection-api'

import { mapState } from '@/store/ults'
import sleep from '@/utils/sleep'
import Overview from '@/views/change-detection/dashboard/Overview.vue'
import TopResult from '@/views/change-detection/dashboard/TopResult.vue'
import Select2 from '@/components/Select2/Select2.vue'
import TimeSerial from '@/views/change-detection/dashboard/TimeSerial.vue'
import MakeOrder from '@/views/aoi/order/MakeOrder.vue'
import TourGuide from '@/components/GuideTour/index.vue'
export default {
  components: { TimeSerial, Select2, TopResult, Overview, CardMapView, MakeOrder, TourGuide },
  data() {
    return {
      isNotification: false,
      currentData: {},
      menu: 'overview',
      dataLoading: false,
      loading: false,
      editing: false,
      types: [],
      type: undefined,
      layers: [],
      source: undefined,
      editFeature: undefined,
      currentAOI: undefined,
      services: [],
      service: undefined,
      data: [],
      result: undefined,
      totalData: 0,
      firstLoad: true,
      frequency: undefined,
      elements: undefined,
    }
  },
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
    sources() {
      return this.service ? this.service.sources : []
    },
    frequencies() {
      return this.service ? this.service.frequencies : []
    },
    notificationId() {
      return this.$route.query?.noti
    },
  },
  async mounted() {
    await this.initDataNoti()
    this.$nextTick(() => {
      this.elements = [
        {
          ref: this.$refs.AOI,
          id: 'AOI',
          title: 'Getting Started',
          text:
            this.listAOI.length > 0
              ? 'Select an AOI to get results'
              : "You don't have any AOIs with results. Please submit an order.",
          position: 'bottom',
          isHideContinueBtn: this.listAOI.length > 0 ? undefined : true,
          buttons:
            this.listAOI.length > 0
              ? undefined
              : [
                  {
                    text: 'Submit Order',
                    action: () => {
                      this.$refs.tourGuide.completeTour()
                      this.$refs.makeOrder.openDialog(null, 'Green Cover')
                    },
                  },
                ],
        },
        {
          ref: this.$refs.service,
          id: 'service',
          title: 'Getting Started',
          text: 'Select a service',
          position: 'bottom',
        },
        {
          ref: this.$refs.source,
          id: 'source',
          title: 'Getting Started',
          text: 'Select a source of result',
          position: 'bottom',
        },

        {
          ref: this.$refs.result,
          id: 'result',
          title: 'Getting Started',
          text: this.data.length > 0 ? 'Select a service to show' : 'No result found, please check your order',
          position: 'bottom',
          buttons:
            this.data.length > 0
              ? undefined
              : [
                  {
                    text: 'Check order',
                    action: () => {
                      this.$refs.tourGuide.completeTour()
                      this.$router.push({
                        name: 'orders',
                        query: {
                          aoiId: this.currentAOI?.uuid,
                          service: this.services.map(service => service.service_id),
                          source: this.source,
                        },
                      })
                    },
                  },
                ],
        },
      ]
    })
  },
  watch: {
    frequency() {
      this.setFrequencyTime()
    },
    menu(val) {
      if (this.menu === 'time') {
        this.frequency = this.frequencies[0]
      }
      this.filterData()
    },
    currentAOI(val) {
      this.service = undefined
      this.result = undefined
      this.source = undefined
    },
    service(val) {
      if (val) {
        if (this.firstLoad || this.services.length === 1) this.source = this.sources ? this.sources[0] : undefined
        if (this.source) this.getListResult()
        else if (this.sources.length === 1 && (this.result || this.menu === 'time')) {
          this.source = this.sources[0]
          if (this.menu === 'time') {
            this.frequency = this.frequencies[0]
          }
          this.filterData()
        } else this.firstLoad = false
      } else {
        this.source = undefined
        this.result = undefined
      }
    },
    async notificationId() {
      await this.initDataNoti()
    },
  },
  methods: {
    async initDataNoti() {
      const query = this.$route.query
      if (query.notification && this.notificationId) {
        const noti = await this.$store.dispatch('notification/getNotification', query.noti)
        this.currentData = {
          ...noti.reference_data?.order,
          aoi: noti.reference_data?.aoi,
          service: noti.reference_data?.service,
        }
        this.isNotification = true
      }
      await this.getListAOI()
    },
    async setFrequencyTime() {
      if (this.menu === 'time') {
        if (!this.$refs.time) {
          await sleep(100)
          await this.setFrequencyTime()
        } else if (this.frequency) this.$refs.time.time = this.frequency.time
      }
    },
    async getListAOI() {
      try {
        this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_group: this.$route.meta.service },
        })
        if (this.firstLoad) {
          await sleep(100)
          if (this.isNotification) {
            this.currentAOI = this.AOIs.find(val => val.uuid === this.currentData.aoi.uuid)
            if (!this.currentAOI) this.currentAOI = this.AOIs[0]
          } else {
            this.currentAOI = this.AOIs[0]
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getService() {
      try {
        this.loading = true
        this.$refs[this.menu].zoomTo(this.currentAOI.bbox)
        const res = await getOrder({ projectId: this.$route.params.id, id: this.currentAOI.uuid })
        this.services = res.data.filter(val => val.service_group === this.$route.meta.service)
        let tmpServices = []
        let tmpFrequencies = []
        this.services.forEach(service => {
          let index = tmpServices.findIndex(val => val.service_name === service.service_name)
          if (index < 0) {
            service.sources = [service.image_source]
            tmpServices.push(service)
            service.frequencies = [
              {
                frequency: service.frequency,
                time: [service.from_date, service.to_date],
              },
            ]
          } else {
            tmpServices[index].sources.push(service.image_source)
            tmpServices[index].frequencies.push({
              frequency: service.frequency,
              time: [service.from_date, service.to_date],
            })
          }
        })

        if (this.services.length === 1) this.service = this.services[0]

        if (this.menu === 'time') {
          this.frequency = this.frequencies[0]
        }

        this.services = []
        this.services = tmpServices
        if (this.isNotification) {
          this.service = this.services.find(val => val.service_id === this.currentData.service.id)
        } else {
          this.service = this.services[0]
        }
        if (this.firstLoad) {
          if (this.service) {
            this.source = this.service.sources[0]
          } else this.firstLoad = false
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getListResult() {
      try {
        if (!this.source) return (this.result = undefined)
        if (this.menu === 'time' && this.frequency) {
          await this.filterData()
          return
        }
        this.loading = true
        this.data = []
        const res = await getChangeDetectionResult({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            source: this.source,
            per_page: 'all',
          },
        })
        this.result = undefined
        this.data = res.data
        if (this.firstLoad) this.result = res.data[0] ? res.data[0] : {}
        if (this.result) {
          await this.filterData()
        }
      } catch (e) {
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    async filterData() {
      await sleep(500)
      this.$refs[this.menu].filterData()
    },
    resetData() {
      this.source = undefined
      this.services = []
      this.service = undefined
      this.data = []
      this.result = undefined
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-layout style="flex: 1; overflow-x: hidden">
    <v-row class="ma-0">
      <v-col class="py-0 pr-2 overflow-y-hidden" cols="12" md="4" style="height: 100%">
        <v-layout class="fill-height overflow-y-hidden" column>
          <div class="pb-2 overflow-y-hidden" style="min-height: 50px; flex: none">
            <v-layout class="overflow-y-hidden" column fill-height>
              <v-card class="custom-card-bg-1 overflow-y-auto" height="100%" width="100%">
                <div class="fill-height pa-2" style="width: 100%">
                  <v-layout align-center fill-height>
                    <DateRangerPicker v-model="time" label="Select time" />
                    <v-btn color="primary" height="50" min-height="0" min-width="0" width="50px" @click="filterData">
                      <v-icon size="22">mdi-filter-outline</v-icon>
                    </v-btn>
                  </v-layout>
                </div>
              </v-card>
            </v-layout>
          </div>
          <v-layout class="pb-2 overflow-y-hidden" style="flex: 7">
            <v-layout class="overflow-y-hidden" column fill-height>
              <v-card class="custom-card-bg-1 overflow-y-auto" height="100%" width="100%">
                <div class="fill-height pa-5" style="width: 100%">
                  <v-row v-if="Object.keys(statistic).length">
                    <v-col class="py-2 pr-2" cols="12" md="12" style="height: 96px">
                      <v-card class="custom-card-bg-1" height="100%" width="100%">
                        <v-layout align-center class="fill-height px-6" justify-center>
                          <v-layout align-center class="fill-height" justify-center>
                            <div
                              class="pr-2"
                              style="color: var(--v-primary-base) !important; font-size: 1.5vw; font-weight: bold"
                            >
                              Total Change:
                            </div>
                            <v-spacer />
                            <v-layout column justify-end>
                              <div class="pb-1" style="font-size: 1vw; font-weight: bold; text-align: end">
                                {{ transformArea(statistic.total.area) }}
                              </div>
                              <div class="pb-1" style="font-size: 13px; font-weight: bold; text-align: end">
                                Features:
                                {{ numberFormat.numberWithCommas(statistic.total.count) }}
                              </div>
                            </v-layout>
                          </v-layout>
                        </v-layout>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="Object.keys(statistic).length">
                    <v-col
                      v-for="(item, key) of statistic.others"
                      :key="key"
                      class="py-2 pr-2"
                      cols="12"
                      md="6"
                      style="height: 96px; cursor: pointer"
                    >
                      <v-card class="custom-card-bg-1 position-relative" height="100%" width="100%">
                        <border-arrow v-if="type === key" />
                        <v-layout align-center class="fill-height" column justify-center>
                          <div style="font-size: 0.95vw; font-weight: bold" v-bind:style="{ color: item.color }">
                            {{ key }}
                          </div>
                          <div style="font-size: 0.8vw; font-weight: bold">
                            {{ transformArea(item.area) }}
                          </div>
                          <div style="font-size: 13px">
                            Total feature:
                            {{ numberFormat.numberWithCommas(item.count) }}
                          </div>
                        </v-layout>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-layout>
          </v-layout>
          <div class="pb-2 overflow-y-hidden" style="min-height: 50px; flex: none">
            <v-layout class="overflow-y-hidden" column fill-height>
              <v-card class="custom-card-bg-1 overflow-y-auto" height="100%" width="100%">
                <div class="fill-height pa-2" style="width: 100%">
                  <v-layout align-center class="px-2" fill-height>
                    <div class="mr-2" style="font-size: 13px; width: 150px">Top the most change in:</div>
                    <v-select v-model="changeIn" :items="resultTypes" dense hide-details label="" outlined></v-select>
                  </v-layout>
                </div>
              </v-card>
            </v-layout>
          </div>
          <v-layout class="overflow-y-auto custom-card-bg-1" column style="flex: 8; border-radius: 8px">
            <v-data-table
              :headers="headers"
              :items="availableData"
              :items-per-page="5"
              :loading="dataLoading"
              :sort-desc="true"
              class="hide-per-page table-small-text"
              sort-by="area"
            >
              <template #[`item.area`]="{ item }">
                <div v-html="transformArea(item.area)"></div>
              </template>
              <template #[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small @click="getLayers(item.id)" v-bind="attrs" v-on="on">
                      <v-icon small>mdi-map</v-icon>
                    </v-btn>
                  </template>
                  <span>Add to map</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
        </v-layout>
      </v-col>
      <v-col class="py-0 pl-2 pr-0 overflow-y-hidden" cols="12" md="8" style="height: 100%">
        <v-layout class="fill-height" column>
          <v-layout class="pb-2 overflow-y-hidden" style="flex: 3">
            <CardMapView ref="map" :inspect="true" :removable="true" />
          </v-layout>
          <v-layout ref="timeChart" class="pt-2 overflow-y-hidden" style="flex: 2; position: relative">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="position: absolute; top: 12px; right: 6px; z-index: 2"
                  x-small
                  @click="fullScreen"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">{{ isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-arrow-expand' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen' }}</span>
            </v-tooltip>
            <v-card class="custom-card-bg-1 overflow-y-hidden" height="100%" width="100%">
              <canvas id="chartTime" height="auto" width="100%"></canvas>
            </v-card>
          </v-layout>
        </v-layout>
      </v-col>
    </v-row>
  </v-layout>
</template>
<script>
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import { getType } from '@/api/order'
import {
  getChangeDetectionLayer,
  getChangeDetectionTimeSerial,
  getFeature,
  getOverview,
} from '@/api/change-detection-api'
import { mapState } from '@/store/ults'
import VectorStyle from '@/utils/VectorStyle'
import bbox from '@turf/bbox'
import sleep from '@/utils/sleep'
import AreaConvert from '@/utils/textArea'
import numberFormat from '@/utils/comma'
import Chart from 'chart.js/auto'
import BorderArrow from '@/components/BorderArrow.vue'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import ElemFullScreen from '@/utils/ElemFullScreen'

export default {
  components: { DateRangerPicker, BorderArrow, CardMapView, numberFormat },
  data() {
    return {
      isFullscreen: false,
      resultTypes: [],
      changeIn: undefined,
      chartData: [],
      labels: [],
      chartTime: undefined,
      chartCompare: undefined,
      statistic: {},
      dataLoading: false,
      loading: false,
      editing: false,
      types: [],
      type: undefined,
      time: [],
      layers: [],
      editFeature: undefined,
      leftMenu: true,
      totalData: 0,
      changeData: [],
      options: {},
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        {
          text: 'Feature',
          align: 'start',
          sortable: true,
          value: 'count',
        },
        {
          text: 'Area',
          align: 'start',
          sortable: true,
          value: 'area',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
    }
  },
  props: {
    aoi: {},
    frequency: {
      type: Object,
      default: () => {},
    },
    selectedService: {
      type: Object,
      default: () => {},
    },
    selectedResult: {
      type: Object,
      default: () => {},
    },
    selectedSource: {},
  },
  computed: {
    availableData() {
      let data = []
      this.changeData.forEach(val => {
        let tmpData = val.vector_data.find(item => item.type === this.changeIn)
        data.push({ date: val.date, id: val.id, ...tmpData })
      })
      return data
    },
    numberFormat() {
      return numberFormat
    },
    ...mapState('AOI', ['AOIs']),
    sources() {
      return this.service.sources
    },
    service: {
      get() {
        return this.selectedService
      },
      set(val) {
        this.$emit('update:selectedService', val)
      },
    },
    currentAOI: {
      get() {
        return this.aoi
      },
      set(val) {
        this.$emit('update:aoi', val)
      },
    },
    source: {
      get() {
        return this.selectedSource
      },
      set(val) {
        this.$emit('update:selectedSource', val)
      },
    },
    result: {
      get() {
        return this.selectedResult
      },
      set(val) {
        this.$emit('update:selectedResult', val)
      },
    },
  },
  mounted() {
    this.changeMapMode('slide')
    this.time = this.frequency ? this.frequency.time : []
    document.addEventListener('fullscreenchange', () => {
      this.isFullscreen = !!document.fullscreenElement
    })
  },
  watch: {
    editing(val) {
      if (!val) {
        this.editFeature = undefined
        this.$refs.map.$refs.map.resetDraw()
        this.changeMapMode('slide')
      }
    },
    options: {
      handler() {
        // this.getData()
      },
      deep: true,
    },
    leftMenu() {
      this.$refs.map.$refs.map.reSize()
    },
    // result(val) {
    //   if (!val || !Object.keys(val).length) {
    //     this.$refs.map.removeAllLayer()
    //     this['chart'].data.datasets = []
    //     this['chart'].data.labels = []
    //     this['chart'].update()
    //     this['chartCompare'].data.datasets = []
    //     this['chartCompare'].data.labels = []
    //     this['chartCompare'].update()
    //     this.changeData = []
    //     this.statistic = {}
    //   }
    // },
  },
  methods: {
    fullScreen() {
      ElemFullScreen.setFullscreenRef(this.$refs.timeChart)
    },
    setFilterType(type) {
      if (this.type === type) this.type = undefined
      else this.type = type
      this.$refs.map.removeAllLayer()
      this.getData()
    },
    async changeMapMode(mode) {
      if (!this.$refs.map) {
        await sleep(100)
        await this.changeMapMode(mode)
      } else {
        await sleep(500)
        this.$refs.map.$refs.layerControl.mapMode = mode
      }
    },
    zoomTo(bbox) {
      this.$refs.map.zoomTo(bbox)
    },
    async getType() {
      try {
        this.loading = true
        const res = await getType({ serviceId: this.service.service_id })
        this.types = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    filterData() {
      this.time = this.time.filter(function (element) {
        return element !== undefined
      })
      if (!this.currentAOI || !this.service || !this.source || this.time.length < 2) return
      this.$refs.map.removeAllLayer()
      this.getData()
    },
    async getData() {
      try {
        if (!this.currentAOI.uuid || !this.frequency) return
        this.dataLoading = true
        this.changeData = []
        const res = await getChangeDetectionTimeSerial({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            source: this.source,
            frequency: this.frequency.frequency,
            from_date: this.time[0],
            to_date: this.time[1],
            showable: true,
            type: this.type,
          },
        })
        this.changeData = res.data.vectors
        this.resultTypes = ['Total', ...res.data.types]
        this.changeIn = this.resultTypes[0]
        this.statistic = res.data.summary
        if (!this.chartTime) this.displayChart(res.data.chart, 'chartTime')
        else {
          this.transformData(res.data.chart)
          this.updateChartData(this.chartData, 'chartTime')
        }
        if (this.changeData.length) await this.getLayers()
      } catch (e) {
        console.log(e)
      } finally {
        this.dataLoading = false
      }
    },
    async getLayers(id = this.changeData[0].id) {
      try {
        this.loading = true
        const res = await getChangeDetectionLayer({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: id,
          payload: { source: this.source },
        })
        res.data.forEach((val, index) => {
          if (index === res.data.length - 1) val.tile_info.name = val.name
          
          if (val.tile_info.type === 'images') {

            let image = {
              id: val.name,
              display: index === res.data.length - 1,
              data: val.tile_info,
              layerType: 'Raster_Image',
              name: val.name,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
            }
            this.$refs.map.$refs.map.addImageToMap(image, undefined, false)
          } else {
            this.$refs.map.$refs.map.addVectorTiles(
              {
                isHover: true,
                display: index === res.data.length - 1,
                data: val.tile_info,
                tiles: [val.tile_info.tile_url],
                bounds: val.tile_info.bbox,
                paint: VectorStyle.getStyle(val.tile_info.styles),
                layerName: 'default',
                name: val.name,
                id: val.name,
                type: val.tile_info.styles.type,
              },
              false
            )
          }
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addLayer(feature) {
      let featureDetail = await this.getFeature(feature)
      this.$refs.map.$refs.map.displayVector(
        '#ff0000',
        featureDetail.geojson,
        'line',
        '#ff0000',
        'change-id-' + featureDetail.id,
        false,
        'polygon',
      )
      this.$refs.map.zoomTo(bbox(featureDetail.geojson))
      await sleep(800)
      this.$refs.map.$refs.map.removeLayer('change-id-' + featureDetail.id)
    },
    async getFeature(feature) {
      try {
        this.loading = true
        const res = await getFeature({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          featureId: feature.id,
          payload: {},
        })
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    cancelEdit() {
      this.editing = false
    },
    transformArea(area, withUnit = true) {
      return AreaConvert.covertArea(area, 'ha', withUnit)
    },
    resetData() {
      this.source = undefined
      this.changeData = []
      this.service = {}
      this.result = undefined
    },
    transformData(chartInfo) {
      this.chartData = []
      this.labels = []
      this.labels = chartInfo.label
      chartInfo.data.forEach(item => {
        this.chartData.push({
          type: 'line',
          label: item.type,
          data: item.data,
          backgroundColor: item.color,
          borderColor: item.color,
          borderWidth: 2,
          pointBorderWidth: 4,
          pointHitRadius: 6,
          pointBackgroundColor: item.color,
        })
      })
    },
    displayChart(chartInfo, chartId) {
      this.transformData(chartInfo)
      const data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      let ctx = document.getElementById(chartId)
      this[chartId] = new Chart(ctx, {
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
          title: {
            color: '#d4d4d4',
            display: true,
            text: '',
          },
          scales: {
            x: {
              ticks: {
                color: '#d4d4d4',
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
            y: {
              ticks: {
                color: '#d4d4d4',
              },
              stacked: false,
              title: {
                color: '#d4d4d4',
                text: 'Square meter',
                display: true,
              },
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
    updateChartData(newData, chartId) {
      this[chartId].data.datasets = newData
      this[chartId].data.labels = this.labels
      this[chartId].update()
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-layout style='flex: 1; overflow-x: hidden'>
    <v-row class='ma-0'>
      <v-col class='py-0 pr-2 overflow-y-hidden' cols='12' md='4' style='height: 100%;'>
        <v-layout class='fill-height overflow-y-auto' column>
          <div class='pb-2 overflow-y-hidden fill-height' style='flex: none; height: fit-content'>
            <v-card
              class='custom-card-bg-1 overflow-y-auto overflow-x-hidden'
              height='100%'
              style='text-align: center'
              width='100%'
            >
              <v-expansion-panels>
                <v-expansion-panel style='background: transparent !important;'>
                  <v-expansion-panel-header>
                    <v-layout align-center>
                      Advanced Filters
                    </v-layout>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-layout>
                      <div style='flex: 1'>
                        <v-layout align-center class='fill-height pr-6' column>
                          <div class='pb-2' style='width: 100%'>
                            <v-layout align-center>
                              <span class='mr-2'>Date</span>
                              <v-divider />
                            </v-layout>
                          </div>
                          <div style='width: 100%'>
                            <v-layout>
                              <div class='pr-1' style='flex: 1'>
                                <DatePicker v-model='filters.from_date' :max='filters.to_date'
                                            label='From' left
                                            outlined />
                              </div>
                              <div class='pl-1' style='flex: 1'>
                                <DatePicker v-model='filters.to_date' :min='filters.from_date'
                                            label='To' left
                                            outlined />
                              </div>
                            </v-layout>
                          </div>
                          <div class='pb-2' style='width: 100%'>
                            <v-layout align-center>
                              <span class='mr-2'>Area</span>
                              <v-divider />
                            </v-layout>
                          </div>
                          <div style='width: 100%'>
                            <v-layout>
                              <div class='pr-1' style='flex: 1'>
                                <v-text-field
                                  v-model.number='filters.from_area'
                                  :max='filters.to_area'
                                  :min='0'
                                  dense
                                  hide-details
                                  label='From' outlined placeholder='From' suffix='ha'
                                  type='number'></v-text-field>
                              </div>
                              <div class='pl-1' style='flex: 1'>
                                <v-text-field
                                  v-model.number='filters.to_area'
                                  :min='filters.from_area ? filters.from_area : 0'
                                  dense
                                  hide-details
                                  label='To' outlined
                                  placeholder='To'
                                  suffix='ha'
                                  type='number'></v-text-field>
                              </div>
                            </v-layout>
                          </div>
                          <div class='pb-2' style='width: 100%'>
                            <v-layout align-center>
                              <span class='mr-2'>Type</span>
                              <v-divider />
                            </v-layout>
                          </div>
                          <div style='width: 100%'>
                            <v-layout>
                              <div class='pr-1' style='flex: 1'>
                                <v-select
                                  v-model='filters.type'
                                  :items='types'
                                  clearable
                                  dense
                                  hide-details
                                  label='Type'
                                  outlined
                                  placeholder='Type'
                                ></v-select>
                              </div>
                              <div class='pl-1' style='flex: 1; text-align: end'>
                              </div>
                            </v-layout>
                          </div>
                        </v-layout>
                      </div>
                      <div class='pt-2' style='width: 48px; flex: none'>
                        <v-layout align-center class='fill-height' justify-center>
                          <v-hover
                            v-slot='{ hover }'
                          >
                            <v-btn :color='hover ? "primary" : "rgba(25,102,247,0.31)"'
                                   min-width='0'
                                   style='height: 100% !important; border-radius: 8px'
                                   width='38'
                                   @click='getData'>
                              <v-icon class='mr-1'>icon-search</v-icon>
                            </v-btn>
                          </v-hover>
                        </v-layout>
                      </div>
                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
          <div class='py-2 overflow-y-hidden' style='flex: none; min-height: 175px'>
            <v-card class='custom-card-bg-1 pa-4' height='100%' width='100%'>
              <v-layout align-center class='fill-height'>
                <v-row v-if='changeData.length' class='ma-0 pa-0'>
                  <v-col class='pl-0 py-0' cols='8'>
                    <v-card-title class='text-no-wrap pt-1 ps-1'>
                      {{ changeData[0].type }}
                    </v-card-title>
                    <v-card-subtitle class='text-no-wrap ps-1 pb-1'>
                      AOI: {{ changeData[0].aoi.name }}
                    </v-card-subtitle>
                    <v-card-text class='d-flex align-center mt-0 pb-1 ps-1'>
                      <div>
                        <p class='text-xl font-weight-semibold primary--text mb-2'>
                          {{ transformArea(changeData[0].area) }}
                        </p>

                        <v-btn
                          color='primary'
                          small
                          @click='setAOIData(changeData[0].aoi)'
                        >
                          Information
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-col>

                  <v-col cols='4'>
                    <img
                      class='greeting-card-trophy'
                      contain
                      height='90'
                      max-width='83'
                      src='@/assets/images/misc/trophy.png'
                    ></img>
                  </v-col>
                </v-row>
                <div v-if='changeData.length' class='fill-height' style='width: 48px; flex: none'>
                  <v-layout align-center class='fill-height' justify-center>
                    <v-hover
                      v-slot='{ hover }'
                    >
                      <v-btn :color='hover ? "primary" : "rgba(25,102,247,0.31)"' min-width='0'
                             style='height: 100% !important; border-radius: 8px'
                             width='38'
                             @click='() => $refs.map.zoomTo(bbox(changeData[0].geojson))'
                      >
                        <v-icon class='mr-1'>icon-gps</v-icon>
                      </v-btn>
                    </v-hover>
                  </v-layout>
                </div>
              </v-layout>
            </v-card>
          </div>
          <v-layout class='pt-2 overflow-y-hidden fill-height' column
                    style='flex: 1; min-height: 220px'>
            <v-card
              class='custom-card-bg-1 overflow-y-auto overflow-x-hidden'
              height='100%'
              style='text-align: center; position: relative'
              width='100%'
            >
              <v-overlay :value='aoiLoading' absolute style='z-index: 9999'>
                <v-progress-circular indeterminate size='64'></v-progress-circular>
              </v-overlay>
              <v-card-title class='py-3'>
                AOI Information
                <v-spacer />
                <div
                  v-if='changeData.length && JSON.stringify(AOIData) === JSON.stringify(changeData[0].aoi)'
                  style='position: relative'>
                  <v-icon color='warning' large>mdi-octagram</v-icon>
                  <span
                    style='position: absolute; top: 7px; left: 14px; font-weight: bold; color: #2700fd'>1</span>
                </div>
              </v-card-title>
              <v-card-text v-if='AOIData'>
                <v-layout align-start class='pl-2' column>
                  <div v-for='(value, key) of AOIData'
                       v-show='key !== "information"  && key !== "uuid"' :key='key'
                       class='py-1' style='font-size: 16px'>
                    <b class='text-capitalize'>{{ key }}:</b>
                    {{ key === 'area' ? transformArea(value) : value }}
                  </div>
                </v-layout>
                <div
                  v-if='AOIGeojson'
                  class='mt-5'
                  style='position: relative;width: 100%;padding-top: 75%; z-index: 1'>
                  <div
                    style='position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100% ; height: 100%; border-radius: 8px'>
                    <MapPreview :id="'AOI'" :geojson='AOIGeojson' />
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-layout>
      </v-col>
      <v-col class='py-0 pl-2 pr-0 overflow-y-hidden' cols='12' md='8' style='height: 100%;'>
        <v-layout class='fill-height' column>
          <v-layout class='pb-2 overflow-y-hidden' style='flex: 3;'>
            <CardMapView ref='map' />
          </v-layout>
          <v-layout class='pt-2 overflow-y-hidden' style='flex: 2;'>
            <v-card class='custom-card-bg-1 overflow-y-hidden' height='100%' width='100%'>
              <v-data-table
                :headers='headers'
                :items='changeData'
                :loading='dataLoading'
                class='hide-per-page table-small-text fill-height'
                fixed-header
                height='100%'
                hide-default-footer
              >
                <template v-slot:item.actions='{ item }'>
                 <v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn icon small v-bind="attrs" v-on="on" @click='() => $refs.map.zoomTo(bbox(item.geojson))'>
      <v-icon small>icon-gps</v-icon>
    </v-btn>
  </template>
  <span>Zoom to</span>
</v-tooltip>
<v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn icon small v-bind="attrs" v-on="on" @click='setAOIData(item.aoi)'>
      <v-icon small>icon-alert_circle</v-icon>
    </v-btn>
  </template>
  <span>AOI information</span>
</v-tooltip>
<v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn v-if="currentUser.permissions.includes('tasking_order.submit')" icon small v-bind="attrs" v-on="on" @click='() => $refs.task.openDialog(item)'>
      <v-icon small>mdi-satellite</v-icon>
    </v-btn>
  </template>
  <span>Add to Tasking</span>
</v-tooltip>
                </template>
                <template v-slot:item.area_percent='{ item }'>
                  <div>{{ item.area_percent }} %</div>
                </template>
                <template v-slot:item.aoi='{ item }'>
                  <div>{{ item.aoi.name }}</div>
                </template>
                <template v-slot:item.area='{ item }'>
                  <div v-html='transformArea(item.area)'></div>
                </template>
                <template v-slot:item.vector.acquired_date='{ item }'>
                  <!--                  {{ convertDate.dateFormatted(item.vector.acquired_date) }}-->
                </template>
              </v-data-table>
            </v-card>
          </v-layout>
        </v-layout>
      </v-col>
    </v-row>
    <Task ref='task' />
  </v-layout>
</template>
<script>
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import { getDetailAOI } from '@/api/aoi-api'
import { getDataTopResult } from '@/api/change-detection-api'
import { mapState } from '@/store/ults'
import bbox from '@turf/bbox'
import sleep from '@/utils/sleep'
import AreaConvert from '@/utils/textArea'
import numberFormat from '@/utils/comma'
import randomColor from '@/utils/randomColor'
import utils from '@/utils/genUUID'
import DatePicker from '@/components/DatePicker.vue'
import { debounce } from 'lodash'
import MapPreview from '@/components/MapPreview.vue'
import Task from '@/components/task/Task.vue'

export default {
  components: { Task, MapPreview, DatePicker, CardMapView, numberFormat },
  data() {
    return {
      AOIGeojson: undefined,
      AOIData: undefined,
      chartData: [],
      filters: {
        from_area: undefined,
        to_area: undefined,
        type: undefined,
        from_date: undefined,
        to_date: undefined,
      },
      labels: [],
      aoiLoading: false,
      dataLoading: false,
      loading: false,
      editing: false,
      types: [],
      layers: [],
      leftMenu: false,
      changeData: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Type', value: 'type' },
        { text: 'AOI', value: 'aoi' },
        { text: 'Area', value: 'area' },
        { text: '% Area ', value: 'area_percent' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  props: {
    aoi: {},
    selectedService: {
      type: Object,
      default: () => {
      },
    },
    selectedResult: {
      type: Object,
      default: () => {
      },
    },
    selectedSource: {},
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    numberFormat() {
      return numberFormat
    },
    ...mapState('AOI', ['AOIs']),
    sources() {
      return this.service.sources
    },
    service: {
      get() {
        return this.selectedService
      },
      set(val) {
        this.$emit('update:selectedService', val)
      },
    },
    currentAOI: {
      get() {
        return this.aoi
      },
      set(val) {
        this.$emit('update:aoi', val)
      },
    },
    source: {
      get() {
        return this.selectedSource
      },
      set(val) {
        this.$emit('update:selectedSource', val)
      },
    },
    result: {
      get() {
        return this.selectedResult
      },
      set(val) {
        this.$emit('update:selectedResult', val)
      },
    },
  },
  mounted() {
    this.changeMapMode('slide')
  },
  watch: {
    editing(val) {
      if (!val) {
        this.editFeature = undefined
        this.$refs.map.$refs.map.resetDraw()
        this.changeMapMode('slide')
      }
    },
    leftMenu() {
      this.$refs.map.$refs.map.reSize()
    },
    currentAOI(val) {
      this.changeFilter()
      if (val) this.$refs.map.zoomTo(this.currentAOI.bbox)
    },
    service() {
      this.changeFilter()
    },
    source() {
      this.changeFilter()
    },
    result() {
      this.changeFilter()
    },
    AOIData(val) {
      if (val) this.displayAOI(val.uuid)
    },
  },
  methods: {
    bbox,
    async setAOIData(aoi) {
      this.aoiLoading = true
      await sleep(500)
      this.AOIData = { ...aoi }
      this.aoiLoading = false
    },
    async changeMapMode(mode) {
      if (!this.$refs.map) {
        await sleep(100)
        await this.changeMapMode(mode)
      } else {
        await sleep(500)
        this.$refs.map.$refs.layerControl.mapMode = mode
      }
    },
    async displayAOI(uuid) {
      try {
        if (!uuid) this.AOIGeojson = undefined
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        this.AOIGeojson = res.data.geojson
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    changeFilter: debounce(function(e) {
      this.getData()
    }, 500),
    zoomTo(bbox) {
      this.$refs.map.zoomTo(bbox)
    },
    filterData() {
      this.getData()
    },
    async getData() {
      try {
        this.dataLoading = true
        this.changeData = []
        let payload = {
          ...this.filters, ...{
            showable: true,
            aoi_uuid: this.currentAOI ? this.currentAOI.uuid : undefined,
            service_id: this.service ? this.service.service_id : undefined,
            vector_uuid: this.result ? this.result.uuid : undefined,
          },
        }
        const res = await getDataTopResult({
          projectId: this.$route.params.id,
          payload: payload,
        })
        this.$refs.map.removeAllLayer()
        this.changeData = res.data.results
        this.addLayerToMap(res.data.results)
        this.types = res.data.filters.types
        if (this.changeData.length) {
          this.AOIData = { ...this.changeData[0].aoi }
          this.displayAOI(this.changeData[0].aoi.uuid)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.dataLoading = false
      }
    },
    addLayerToMap(data) {
      this.$refs.map.removeAllLayer()
      data.forEach((item, ind) => {
        item.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: item.geojson,
            },
          ],
        }
        this.$refs.map.$refs.map.addGeoJsonToMap(
          item.id,
          item.geojson,
          randomColor.getColor(ind),
          utils.getUUID(),
          'line',
          true,
        )
      })
    },
    cancelEdit() {
      this.editing = false
    },
    transformArea(area, withUnit = true) {
      return AreaConvert.covertArea(area, 'ha', withUnit)
    },
    resetData() {
      this.source = undefined
      this.changeData = []
      this.service = {}
      this.result = undefined
    },
  },
}
</script>

<style scoped></style>
